.blog-content {
    padding: 15rem 15rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.blog-content__image {
    flex-basis: 70%;
}

.blog-content__text-highlight {
    color: $yellow;
}



