.landing-content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.landing-content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.landing-content__img {
    width: 50vw;
}

.landing-text-wrapper {
    font-size: $m-size;
    width: 50vw;
    text-align: center;
}

[data-aos] {
    pointer-events: none;
    &.aos-animate {
      pointer-events: auto;
    }
  }

@media (min-width: 600px) {

    .landing-content__img {
        width: 30vw;
    }

}

@media (min-width: 800px) {
    
    .landing-content {
        height: 90vh;
    }
}

//desktop
@media (min-width: 1280px) {

    .landing-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        height: 90vh;
    }

    .landing-content__img {
        position: static;
        width: 10vw;
        align-self: center;
        padding-right: 0.5vw;
    }

    .landing-content__wrapper {
        display: flex;
        flex-direction: row;
        width: 24.5vw;
    }

    .landing-text-wrapper {
        align-self: center;
        font-size: $mobile-title;
        width: max-content;
    }
}