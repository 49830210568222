//colour variables
$dark-gray: #4A4A4A;
$light-gray: #D8D8D8;
$yellow: #F5A623;
$yellow-alt: #F6A535;
//$green: #0F5A5A;
$green: #184949;
$off-white: #f5f5f5;

//spacing variables
$xxs-size: 0.25rem;
$xs-size: 0.5rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$mobile-title: 2rem;
$title: 2.5rem;
$l-size: 3.2rem;
$xl-size: 4.5rem;
$xxl-size: 5rem;

//font style variables
$title-font: 'Raleway', sans-serif;
$body-font: 'Montserrat', sans-serif;
$svg-font: 'Myriad Pro', sans-serif;

//device variables
$desktop-breakpoint: 45rem;