.mobile-menu__wrapper {
    width: 10vw;
    height: 10vh;
    position: fixed;
    right:0;
    top:0;
    z-index: 2;
}

.mobile-menu__wrapper-open {
    width: 100vw;
    height: 100vh;
    position: fixed;
    right:0;
    top:0;
    z-index: 2;
}

.menu-icon {
    position: absolute;
    right: 0;
    top: 0;
    margin: 5vw;
    width: 5vw;
    z-index: 3;
}

.menu-close {
    display: none;
}

.menu-list__wrapper {
    height: inherit;
}

.menu__site-icon {
    width: 35vw;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-bottom: $m-size;
}

.menu-list {
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $off-white;
}

.menu-option {
    padding: 2%;
    margin: 2% 0;
    display: flex;
    align-items: center;
}

.menu-link {
    color: $dark-gray;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    width: 30vw;
}

.menu-link__active {
    color: $yellow;
}

.currentSection {
    background-color: $yellow;
}

.open-cv {
    color: white;
    background-color: $yellow;
    border: 1px solid $yellow;
    padding: 2vw;
    font-weight: lighter;
}

.mobile-social__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 10vh;
    width: 100%;
    padding-top: 2.5vh;
}

.mobile-social {
    width: 10vw;
    padding: 0 1vw;
}

.mobile-git__wrapper, .mobile-linkedin__wrapper{
    z-index: 0;
}